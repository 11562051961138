import { PageBlock } from 'ui/layout/PageBlock';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { FunctionComponent, useEffect } from 'react';
import { provider, useInstance, useInstances } from 'react-ioc';
import { SectionWrapper } from '../ui/layout/SectionWrapper';
import { AgencyModel } from '../assets/models/agencies/Agency.model';
import { AgenciesStore } from '../Stores/Agencies.store';
import { AgencyInvoiceStore } from '../Stores/AgencyInvoice.store';
import { DashboardViewStore } from '../Stores/viewStore/DashboardView.store';
import DashboardRecap from './Dashboard/DashboardRecap/DashboardRecap';
import DashboardTabsComponent from './Dashboard/DashboardTabsComponent';
import MillesimeBannerComponent from './Dashboard/MillesimeBanner/MillesimeBannerComponent';
import { TabBar, TabDefinition } from '../ui/TabBar';
import { getStartOfMillesimeDate } from '../assets/utils/millesime/millesime.util';

type LocalStore = [AgenciesStore, AgencyInvoiceStore];

const DashboardPage: FunctionComponent = provider(
  DashboardViewStore,
)(observer(() => {
  const [agenciesStore, invoiceStore]: LocalStore = useInstances<LocalStore>(AgenciesStore, AgencyInvoiceStore);
  const store: DashboardViewStore = useInstance<DashboardViewStore>(DashboardViewStore);
  const currentAgency: AgencyModel = agenciesStore.currentAgency;

  const tabs: TabDefinition[] = [
    {
      label: 'Depuis le début de l’année',
      Component: () => <DashboardTabsComponent from={moment().startOf('year').add(1, 'day').unix() * 1000}/>,
      isVisible: () => true,
    },
    {
      label: 'Depuis un an',
      Component: () => <DashboardTabsComponent from={moment().subtract(1, 'year').add(1, 'day').unix() * 1000}/>,
      isVisible: () => true,
    },
    {
      label: 'Sur le millésime',
      Component: () => <DashboardTabsComponent from={moment(getStartOfMillesimeDate(new Date())).add(1, 'day').unix() * 1000}/>,
      isVisible: () => true,
    },
  ];

  useEffect(() => {
    if (currentAgency) {
      store.setIsAnyProvisions(false);

      invoiceStore.fetchAgencyInvoices(currentAgency.uid)
        .then(() => {
          if (invoiceStore.invoices.length > 0) {
            store.setIsAnyProvisions(true);
          }
        })
        .catch(e => console.error(e));
    }
  }, [currentAgency]);

  return (
    <PageBlock className={'space-y-6'}>
      <MillesimeBannerComponent/>
      <DashboardRecap/>
      {store.isAnyProvisions && agenciesStore.currentAgency &&
        <SectionWrapper>
            <TabBar
                value={store.tabNumber}
                tabs={tabs}
                onChange={(event, newTabNumber) => store.setTabNumber(newTabNumber)}
            />
        </SectionWrapper>
      }
    </PageBlock>
  );
}));

export default DashboardPage;