import React, { FunctionComponent, useEffect, useState } from 'react';
import RGPDStandard from './RGPDStandard';
import { useInstance } from 'react-ioc';
import { AgenciesStore } from '../../Stores/Agencies.store';
import RGPDPublicMealSubsidy from './RGPDPublicMealSubsidy';
import { Spinner } from '../../ui/Spinner';

const RGPDPage: FunctionComponent = () => {
  const agenciesStore: AgenciesStore = useInstance<AgenciesStore>(AgenciesStore);

  const [agency, setAgency] = useState(null);

  useEffect(() => {
    setAgency(agenciesStore.currentAgency);

  }, [agenciesStore.currentAgency]);

  return (
    <>
      {agency ?
        (agency?.contractType === 'PUBLIC_MEAL_SUBSIDY'
          ? <RGPDPublicMealSubsidy/>
          : <RGPDStandard/>)
        :
        <div className={'flex justify-center items-center w-screen h-screen'}>
          <Spinner size={'lg'}/>
        </div>
      }
    </>
  );
};
export default RGPDPage;