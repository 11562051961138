import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import FooterComponent from '../../Layout/FooterComponents/FooterComponent';

const useRGPDStyles = makeStyles(() => ({
  root: {
    padding: '4rem 10rem',
    width: '100%',
  },
  title: {
    fontSize: '2rem',
    margin: '3rem 0',
    textAlign: 'center',
  },
  link: {
    color: 'black',
  },
  hatP: {
    paddingBottom: '1rem',
  },
}));

const RGPDPublicMealSubsidy: FunctionComponent = () => {
  const CLASSES = useRGPDStyles();

  return (
    <>
      <article className={CLASSES.root}>
        <h2 className={CLASSES.title}>POLITIQUE DE PROTECTION DES DONNEES PERSONNELLES DU SITE WEB ET DU COMPTE
          EXTRANET</h2>
        <section>
          <p className={CLASSES.hatP}>
            Comme précisé dans nos mentions légales (<a className={CLASSES.link}
                                                        href={'https://web-employeur.stayopen.io/legalnotice'}>https://web-employeur.stayopen.io/legalnotice</a>),
            OPEN!
            SAS (ci-après « <strong>OPEN!</strong> ») est l’éditeur du site https://web-employeur.stayopen.io/. Ce Site
            contient
            une partie privée accessible uniquement aux clients d’OPEN! (le « <strong>Compte Extranet</strong> »). Dans
            le cadre
            de l’exploitation du Site, OPEN! est amenée à traiter, seule ou conjointement avec l’Employeur,
            certaines Données à caractère personnel.
          </p>
          <p className={CLASSES.hatP}>
            La protection de vos Données est un sujet que OPEN! prend très au sérieux. Nous vous présentons
            dans la présente politique de protection des Données personnelles (ci-après la
            « <strong>Politique</strong> ») nos
            pratiques en matière de Traitements (recueil, conservation, utilisation, partage) et de protection des
            Données que nous traitons dans le cadre de votre utilisation du Site ou du Compte Extranet. Cette
            Politique présente également les droits dont vous disposez sur vos Données et les modalités pour les
            exercer.
          </p>
          <p>
            Les termes données à caractère personnel (les « <strong>Données</strong> »), Traitements, Responsable du
            Traitement, Responsable Conjoint du Traitement (les « <strong>Responsables Conjoints</strong> »),
            Sous-traitant,
            Destinataire, Tiers, Consentement, Autorité de contrôle ont les définitions qui leurs sont données
            dans le Règlement européen N°2016/679 du 27 avril 2016 et la loi Informatique et Libertés N°78-17
            du 6 janvier 1978 modifiée (ci-après collectivement la « Réglementation »). Tout autre terme
            débutant par une majuscule et non défini dans la présente Politique ou les Conditions Générales aura
            la signification qui lui est donnée par le RGPD.
          </p>
        </section>

        <section>
          <h3>1. Données traitées et finalités du Traitement lorsque OPEN! est Responsable du Traitement</h3>
          <p>
            OPEN! commercialise ses Services exclusivement auprès d’organisations publiques ou privées. Le
            présent Site n’est pas destiné aux particuliers qui sont susceptibles d’être de simples bénéficiaires
            des Services commercialisés par OPEN! auprès de ses clients professionnels. Dans le cadre du Site et
            du Compte Extranet, nous sommes amenés à traiter des Données aux fins de la gestion de notre
            relation commerciale, de la gestion de la relation contractuelle, de la facturation, de l’assistance et
            de la gestion des demandes d’exercice de droits.
          </p>
          <section>
            <h4>Gestion de la relation contractuelle avec l’Employeur et de la facturation</h4>
            <p>
              Lorsque OPEN! entre en relation contractuelle avec l’Employeur, elle est amenée à traiter certaines
              Données relatives aux employés de l’Employeur chargés de gérer la relation avec OPEN! et de lui
              transmettre toutes les informations et Données dont elle a besoin pour exécuter le Contrat souscrit
              par l’Employeur. Cela comprend notamment la gestion des accès au Compte Extranet de l’Employeur
              ainsi que l’ensemble des Données nécessaires à l’émission, l’envoi et au recouvrement des factures.
              Les Données susceptibles d’être traitées dans ce cadre sont : civilité, prénom, nom, coordonnées
              professionnelles (adresse e-mail et postale, numéro de téléphone), fonction, login et mot de passe
              permettant d’accéder au Compte Extranet.
            </p>
          </section>
          <section>
            <h4>Gestion de l’assistance de l’Employeur</h4>
            <p>
              OPEN! assure une assistance de l’Employeur et des personnes qu’il a désignées pour accéder au
              Compte Extranet afin de les accompagner dans la bonne utilisation du Service.
              Les Données susceptibles d’être traitées dans ce cadre sont : prénom, nom, coordonnées professionnelles
              (téléphone, adresse, e-mail), sujet de la demande d’assistance, détail de la demande d’assistance.
            </p>
          </section>
        </section>

        <section>
          <h3>2. Données traitées et finalités du Traitement lorsque OPEN! et l’Employeur sont Responsables Conjoints du
            Traitement</h3>
          <p>
            OPEN! et l’Employeur sont Responsables Conjoints concernant certaines opérations de Traitement
            réalisées dans le Compte Extranet et rendues nécessaires dans le cadre des Services, en particulier
            celles liées à l’émission par l’Employeur des coupons repas, qui sont des titres spéciaux de paiement
            dématérialisés.
          </p>
          <p>
            Nous vous indiquons ci-dessous les finalités pour lesquelles OPEN! et l’Employeur sont Responsables
            Conjoints, la nature de leur intervention respective et les catégories de Données traitées
            conjointement.
          </p>
          <section>
            <h4>Emission des droits à coupons repas</h4>
            <p>
              OPEN! met à disposition de l’Employeur le Compte Extranet qui lui permet de gérer les droits à
              coupons repas de ses Salariés. Dans ce cadre, les catégories de Données traitées sont : des Données
              d’identification, des coordonnées bancaires, des Données de connexion et des Données relatives aux
              droits alloués aux Salariés. OPEN! collecte, stocke, consulte et utilise les Données transmises par
              l’Employeur dans le Compte Extranet car elles sont nécessaires dans le cadre de l’émission des TSPD.
            </p>
          </section>
          <section>
            <h4>Emission des TSPD</h4>
            <p>
              OPEN! indique sur le Compte Extranet de l’Employeur le montant qu’il doit rembourser pour chaque
              salarié aux commerçants.
            </p>
          </section>
          <section>
            <h4>Gestion des demandes d’exercice de droits</h4>
            <p>
              OPEN! est désignée pour traiter les demandes d’exercice de droit pour les Traitements réalisés
              conjointement avec l’Employeur. Lorsque vous nous contactez pour exercer vos droits (voir plus loin
              la description des droits dont vous disposez sur vos Données) nous sommes susceptibles de traiter
              les Données suivantes : e-mail, nom, prénom, date de votre demande, message que vous nous avez
              adressé pour décrire votre demande, date de notre réponse, message contenu dans notre réponse.
            </p>
          </section>
        </section>
        <section>
          <h3>3. Bases légales du Traitement</h3>
          <p>
            Conformément à la Réglementation, chaque Traitement de Données par OPEN! est justifié par l’un des motifs
            suivants :
          </p>
          <ul>
            <li><p>
              Intérêts légitimes en matière de gestion de notre relation commerciale consécutives aux demandes que
              vous
              nous adressez à l’aide des formulaires ou autres moyens de contacts mis à disposition sur le Site. Nous
              utilisons les Données recueillies aux fins de gestion de nos prospects et clients professionnels. Ces
              Données
              pourront ainsi être utilisées afin de répondre à vos demandes d’information de même que dans le cadre de
              nos activités de prospection commerciale et d’information de nos clients telles que le Traitement des
              demandes issues du Site, l’envoi d’offres susceptibles de vous intéresser, l’envoi d’informations sur
              l’actualité de notre société.
            </p></li>
            <li><p>
              Consentement lorsque OPEN! recueille des Données optionnelles dans les formulaires proposés aussi bien
              sur
              les supports de communication publics, tels que le Site, que dans le Compte Extranet.
            </p></li>
            <li><p>
              Exécution du contrat entre OPEN! et l’Employeur dans le cadre de la gestion de la relation
              contractuelle,
              de la facturation, de l’assistance, de l’émission des droits à coupons-repas et l’émission des TSPD.
            </p></li>
            <li><p>
              Respect des obligations légales auxquelles nous sommes soumis dans le cadre du Traitement de vos
              demandes
              d’exercice de droits ou de requêtes des autorités compétentes (voir plus bas « Destinataires des Données
              »).
            </p></li>
          </ul>
          <p>
            Lorsque l’Employeur est Responsable Conjoint des Traitements avec OPEN!, celui-ci détermine toute base
            légale
            lui permettant de traiter les Données. Nous vous invitons à vous rapprocher de l’Employeur pour obtenir plus
            d’information à ce sujet.
          </p>
        </section>
        <section>
          <h3>4. Destinataires des Données</h3>
          <ul>
            <li><p>
              Données transférées à des Tiers : les Données que vous nous confiez sont réservées à
              notre usage exclusif et
              ne sont transmises à aucun Tiers hormis les Sous-traitants que nous utilisons pour leur Traitement et
              notre
              prestataire de services de paiement pour faire parvenir les remboursements de la Participation Employeur
              aux
              Salariés le cas échéant.
            </p></li>
            <li><p>
              Données transférées à nos Sous-traitants :nous ne transmettons à nos Sous-traitants
              que les Données dont
              ils ont besoin pour effectuer leurs services et nous exigeons qu’ils n’utilisent pas vos Données à
              d’autres fins.
              Ils n’agissent que conformément à nos instructions et sont contractuellement tenus d’assurer un niveau
              de
              sécurité et de confidentialité de vos Données identique au nôtre et de se conformer à la Réglementation
              applicable
              sur la protection des Données à caractère personnel.
            </p></li>
            <li><p>
              Données transférées à notre prestataire de services de paiement MANGOPAY : lorsque
              OPEN! est chargée de
              réaliser les remboursements de la Participation Employeur, nous transmettons à MANGOPAY les Données dont
              elle a
              besoin pour exécuter les opérations bancaires. MANGOPAY agit en tant que Responsable du Traitement
              indépendant des
              Données que nous lui avons confiées. Pour plus d’information sur les Traitements mis en œuvre par
              MANGOPAY, nous
              vous invitons à consulter sa politique de confidentialité disponible à cette adresse :
              <a className={CLASSES.link} href="https://mangopay.com/privacy-statement"
                 target="_blank">https://mangopay.com/privacy-statement</a>.
            </p></li>
            <li><p>
              Données transférées aux autorités compétentes : conformément à la règlementation en
              vigueur, les Données
              peuvent être transmises aux autorités compétentes sur requête et notamment aux organismes publics, aux
              auxiliaires
              de justice, aux officiers ministériels, aux organismes chargés d’effectuer le recouvrement de créances,
              exclusivement pour répondre aux obligations légales, ainsi que dans le cas de la recherche des auteurs
              d’infractions
              commises sur internet.
            </p></li>
          </ul>
        </section>
        <section>
          <h3>5. Durées de conservation</h3>
          <p>
            Vos Données ne sont conservées par OPEN! que pendant la durée strictement nécessaire à l’accomplissement des
            finalités énoncées dans cette Politique :
          </p>
          <ul>
            <li><p>
              Données liées à la gestion de la relation contractuelle et de la facturation :
              conservées pendant la durée de la relation contractuelle ainsi que pendant les durées légales lorsque
              cela est nécessaire (dix [10] ans pour les factures).
            </p></li>
            <li><p>
              Données liées à la gestion de l’assistance de l’Employeur :
              conservées pendant la durée de la relation contractuelle.
            </p></li>
            <li>
              <p>
                Données liées à la gestion des droits à coupons de repas :
                conservées pendant la durée de prescription légale :
              </p>
              <ul>
                <li><p>
                  pendant la durée du contrat entre OPEN! et l’Employeur : cinq (5) années précédant l’année civile
                  en cours ;
                </p></li>
                <li><p>
                  au terme du contrat entre OPEN! et l’Employeur : cinq (5) années civiles suivant la fin du
                  contrat.
                </p></li>
              </ul>
            </li>
            <li><p>
              Données utilisées aux fins de Traitement des demandes d’exercice des droits :
              conservées pendant cinq ans.
            </p></li>
          </ul>
          <p>
            Vos Données sont effacées lorsque les durées de conservation expirent. Néanmoins, vos Données pourront être
            archivées au-delà des durées prévues pour les besoins de la recherche, la constatation et de la poursuite
            des infractions pénales dans le seul but de permettre, en tant que de besoin, la mise à disposition de vos
            Données à l’autorité judiciaire. L’archivage implique que vos Données ne seront plus consultables en ligne
            mais seront extraites et conservées sur un support autonome et sécurisé.
          </p>
        </section>

        <section>
          <h3>6. Transferts de Données dans d’autres pays</h3>
          <p>
            Nous sommes susceptibles de faire appel à des prestataires situés en-dehors de l’Union Européenne. Dans un
            tel cas, les transferts hors Union Européenne sont réalisés de manière sécurisée et légale :
          </p>
          <ul>
            <li><p>
              soit en transférant les Données à un Destinataire situé dans un pays bénéficiant d’une décision
              d’adéquation de la Commission Européenne ;
            </p></li>
            <li><p>
              soit en exécutant ou faisant exécuter les Clauses Contractuelles Types approuvées par la Commission
              européenne ;
            </p></li>
            <li><p>
              soit par le recours à des Règles Internes d’Entreprise Contraignantes validées par les Autorités de
              contrôle compétentes ;
            </p></li>
            <li><p>
              soit en recourant à toutes garanties appropriées visées à l’article 46 du Règlement européen N°2016/679
              du 27 avril 2016.
            </p></li>
          </ul>
        </section>

        <section>
          <h3>7. Protection de vos Données</h3>
          <p>
            Nous prenons des mesures adéquates sur le plan de la technique et de l’organisation pour interdire l’accès
            non autorisé, la modification, divulgation, perte ou destruction de vos Données.
          </p>
        </section>

        <section>
          <h3>8. Vos droits sur vos Données</h3>
          <p>
            Conformément à la Réglementation applicable, vous bénéficiez d’un certain nombre de droits relatifs à vos
            Données :
          </p>
          <ul>
            <li><p>droit d’accès et d’information ;</p></li>
            <li><p>droit de rectification des Données inexactes ;</p></li>
            <li><p>droit d’effacement de vos Données ;</p></li>
            <li><p>droit à la limitation du Traitement de vos Données ;</p></li>
            <li><p>
              droit à la portabilité des Données dans un format structuré, couramment utilisé et lisible par une machine
              ;
            </p></li>
            <li><p>droit d’opposition au Traitement de vos Données ;</p></li>
            <li><p>
              droit de retirer votre Consentement à tout moment lorsque le Traitement de vos Données est basé sur votre
              Consentement ;
            </p></li>
            <li><p>
              droit de déposer une plainte auprès d’une Autorité de contrôle, en France l’Autorité de contrôle est la
              CNIL :
              <a className={CLASSES.link} href="https://www.cnil.fr/">https://www.cnil.fr/</a> ;
            </p></li>
            <li><p>
              droit de donner des directives concernant le sort de vos Données après votre décès.
            </p></li>
          </ul>
          <p>
            Pour exercer ces droits, vous pouvez nous contacter à l’adresse suivante :
            <a className={CLASSES.link} href="mailto:data-protection@stayopen.io">data-protection@stayopen.io</a>.
          </p>
          <p>
            Notez que nous pouvons exiger un justificatif de votre identité pour l’exercice de ces droits.
          </p>
        </section>

        <section>
          <h3>9. Modification la Politique</h3>
          <p>
            Nous pouvons être amenés à modifier occasionnellement la présente Politique, afin notamment de nous
            conformer à toute évolution réglementaire, jurisprudentielle, éditoriale ou technique. Le cas échéant, nous
            changerons la date de « dernière mise à jour » et indiquerons la date à laquelle les modifications ont été
            apportées. Lorsque cela sera nécessaire, nous vous informerons et/ou solliciterons votre accord.
          </p>
        </section>

        <section>
          <h3>10. Contact</h3>
          <p>
            Pour toute question relative à la présente Politique ou pour toute demande relative à vos Données, vous
            pouvez nous contacter en adressant un e-mail à l’adresse suivante : <a className={CLASSES.link}
                                                                                   href={'data-protection@stayopen.io'}>data-protection@stayopen.io</a> ;
          </p>
        </section>
        <aside><p>Date de dernière mise à jour : 12 novembre 2024.</p></aside>
      </article>
      <FooterComponent/>;
    </>
  )
    ;
};

export default RGPDPublicMealSubsidy;
